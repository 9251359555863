@import '@packages/assets/fonts/font-awesome-4.6.3/css/font-awesome.css';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: ProximaNova;
}

ul {
  margin: 1em 0;
  padding: 0 0 0 40px;
}

.main {
  animation: fadein 0.1s linear;

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
  src: local('ProximaNova'), local('ProximaNova-Regular'),
    url('../../../assets/fonts/proximanova/Regular.ttf');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: bold;
  src: local('ProximaNova'), local('ProximaNova-Bold'),
    url('../../../assets/fonts/proximanova/Semibold.otf');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: italic;
  font-weight: normal;
  src: local('ProximaNova'), local('ProximaNova-It'),
    url('../../../assets/fonts/proximanova/Regular-It.otf');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: italic;
  font-weight: bold;
  src: local('ProximaNova'), local('ProximaNova-Bold'),
    url('../../../assets/fonts/proximanova/Semibold-It.otf');
}

.mainLayoutContainer {
  @import './constants';
  height: 100vh;
  animation: fadeInAnimation ease-out 0.1s;
  animation-iteration-count: 1;

  .banner {
    padding: 15px;
    display: flex;
    gap: 20px;
    height: 50px;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    .closeBtn {
      font-size: 1.2rem;
      cursor: pointer;
      transform: rotate(45deg);
    }
  }

  .Incident {
    background-color: lighten($Incident, 40);
    color: darken($Incident, 20);
    box-shadow: lighten($Incident, 10) 1px 2px 1px;
  }

  .Warning {
    background-color: lighten($Warning, 38);
    color: darken($Warning, 20);
    box-shadow: lighten($Warning, 20) 1px 2px 1px;
  }

  .Alert {
    background-color: lighten($Alert, 38);
    color: darken($Alert, 10);
    box-shadow: lighten($Alert, 20) 1px 2px 1px;
  }

  .Information {
    background-color: lighten($Information, 50);
    color: darken($Information, 10);
    box-shadow: lighten($Information, 30) 1px 2px 1px;
  }
}

.links line {
  stroke: #999;
  stroke-opacity: 0.4;
  fill-opacity: 0.9;
}

.nodes circle {
  stroke: #fff;
  stroke-width: 1.5px;
}

.nodes svg {
  overflow: visible;
}

.nodes text {
  margin-left: 20;
  font-family: ProximaNova, sans-serif;
}

.__react_component_tooltip {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.mapTooltipClass {
  font-size: 20px !important;
  pointer-events: auto !important;
  border: 2px solid !important;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }

  &:after {
    border-top-color: black !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
  }
}

.scrollStyle {
  height: 138px !important;
}

.button_icon {
  height: 45px !important;
  min-width: 45px !important;
  margin: 10px 10px 0px 0px !important;
  padding: 0px !important;
}

.button_icon_no_margin {
  height: 45px !important;
  min-width: 45px !important;
  padding: 0px !important;
}

.button_compliance {
  background-color: black !important;
  margin-right: 15px !important;
  height: 50px !important;
  width: 50px !important;
  min-width: 50px !important;
}

.button_header {
  min-width: 160px !important;
}

.button_confirm_dialog {
  height: 36px !important;
}

.buttonLabel_confirm_dialog {
  font-size: 14px !important;
}

.button_risk {
  min-width: 16% !important;
  height: 36px !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.secondarybutton {
  background-color: #efefef !important;
  margin-top: 40px;
}

.button_result_risk {
  min-width: 20% !important;
  height: 36px !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.buttonLabel_upload {
  font-size: 16px !important;
  height: 43px;
  padding-left: 0px;
  padding-right: 0px;
  line-height: initial;
}

.button_black {
  height: 40px !important;
  background-color: black !important;
  min-width: 140px;
}

.buttonLabel_legalgrounds {
  font-size: 16px !important;
}

.divider_dark {
  height: 0px;
  background-color: black !important;
}

.button_goBack {
  position: fixed;
  top: 110;
}

.buttonLabel_goBack {
  font-size: 20px !important;
  vertical-align: unset;
}

.button_zoom {
  min-width: 30px !important;
}

.buttonLabel_zoom {
  font-size: 40px !important;
}

.buttonLabel_whatsthis {
  text-decoration: underline !important;
  font-size: 15px !important;
  color: black !important;
  margin-left: -10px !important;
}

.button_skip {
  height: 50px;
  min-width: 150px !important;
  margin-top: 20px !important;
  background-color: #ffffff !important;
}

.button_skip_label {
  color: #000000 !important;
  font-size: 22px !important;
  font-weight: 520;
}

.add-dialog-header {
  padding-left: 25px;
  justify-content: flex-start;
  display: flex;
  font-weight: 500 !important;
}

.dialog-content-style {
  color: #0009 !important;
  font-size: 16px !important;
}

.center-header-dialog {
  justify-content: center;
  display: flex;
  font-weight: 500 !important;
  padding-left: 25px;
}

.legal-ground-header {
  width: 50%;
  display: flex;
  justify-content: center;
}

.buttonLabel_metadataDownload {
  text-decoration: underline !important;
  font-size: 16px !important;
}

.button_no_hover {
  margin-left: 0px;

  &:hover {
    background-color: transparent !important;
  }
}

.badge {
  min-width: 0px !important;
}

.richText_Editor {
  height: 300px;
}

.other_textfield_disabled {
  color: black !important;
}

#text-field::-ms-clear,
#textfield::-ms-clear,
#text-field-note::-ms-clear,
#single_line_textField::-ms-clear,
#multi_line_textField::-ms-clear {
  display: none;
}

.table-row {
  &:hover {
    background-color: #dbd9d9;
  }
}

.even-row {
  background-color: #efefef;
}

.clickable {
  cursor: pointer;
}

.selectionCell {
  width: 50px;
}

.sub-menu {
  &:hover {
    background-color: #dbd9d9;
  }
}

.menu-item {
  &:hover {
    width: 240px !important;
  }
}

.selected-Item {
  line-height: 25px !important;
}

.list_sub_header {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.word_break {
  word-break: break-word;
}

.loaderClass {
  top: 25% !important;
}

.merge_button {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.disabled_style {
  cursor: not-allowed !important;
}

.navbar_menuItem {
  cursor: default !important;

  &:hover {
    background-color: transparent !important;
  }
}

.language_menuItem {
  min-width: 160px;
}

.item-row-icons:hover .delete-edit-icon,
.item-row-icons:hover .dpo-icon {
  display: flex;
}

.dpo-icon,
.delete-edit-icon {
  display: none;
}

.item-action-icons {
  display: flex;
  min-width: 140px;
  justify-content: flex-end;
  align-items: center;
}

.table-row:hover .delete-edit-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.chip_root {
  padding-left: 10px !important;
  min-height: 35px !important;
  height: 100% !important;
  width: 98% !important;
  max-height: 80px !important;
  overflow-y: auto !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px;

  &:hover {
    border: 1px solid !important;
  }
}

.rte-no-margin * {
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}

.disabled_form_style {
  pointer-events: none !important;
  opacity: 1;
}

.action_button {
  height: 40px !important;
  min-width: 150px !important;
  padding: 0px !important;
}

.action_table_style {
  width: 100% !important;
}

.comment_button_style {
  height: 30px !important;
  font-size: 11px !important;
  line-height: 1 !important;
}

.action_button_style {
  height: 35px !important;
  width: 130px !important;
  line-height: 1 !important;
}

.action_button_label {
  font-size: 14px !important;
}

.request_root_style {
  padding-top: 5px !important;
}

.mentions {
  margin-top: 5px;
  font-size: 11pt;
}

.mentions--multiLine .mentions__input {
  outline: 0;
  padding: 9px;
  border: 0;
  top: 1px !important;
}

.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  max-height: 300px !important;
  overflow-y: auto;
  font-size: 11pt;
}

.mentions__suggestions__item {
  padding: 10px 15px;
}

.outlined-select {
  padding: 10px !important;
}

.gridTileWrap {
  color: black !important;
}

.gridTilebarRoot {
  background: transparent !important;
}

.gridTilebarRootWithShade {
  background: #efefef !important;
}

.avtarImgRoot {
  width: auto !important;
}

.avatarRoot {
  justify-content: inherit !important;
}

.avtarImg {
  object-fit: contain !important;
}

.confirmationList li {
  margin-bottom: 10px;
}

.linkClass {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.MuiMenuItem-root {
  min-height: 48px !important;
}

.sweet-progress-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pageContainer {
  max-width: 100%;
  padding: 30px;
  padding-bottom: 0;
  display: grid;
  height: calc(100% - 36px);
  gap: 30px;
  grid-auto-rows: minmax(auto, auto);
  grid-template-rows: 50px auto 50px;
  grid-row-gap: 20px;

  .back-btn {
    font-weight: bold;
  }

  .headerSection {
    grid-column-start: 2;
    grid-column-end: 4;
    display: flex;
    min-width: 0;
    overflow: hidden;

    .recordNumber {
      height: 28px;
      width: fit-content;
      padding: 0 10px;
      line-height: 30px;
      color: #fff;
      font-weight: bold;
      border-radius: 2px;
      letter-spacing: 1px;
      margin-right: 10px;
    }

    .recordNameIcon {
      display: flex;
      width: 100%;
      overflow: hidden;
      margin-right: 30px;
    }

    .recordName {
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 22px;
      line-height: 30px;
    }

    .editIcon {
      height: 30px;
      padding: 5px;
      cursor: pointer;
    }

    .optionButtons {
      display: flex;
      gap: 10px;
      padding-left: 30px;
      margin-left: auto;
    }
  }

  main {
    margin-top: -20px;
    border-radius: 5px;
    height: 100%;

    .picklist {
      height: 40px;
      line-height: 25px;
      text-align: left;
      z-index: 2;

      .MuiSelect-selectMenu {
        height: 23px;
        background-color: transparent;
      }
    }
  }

  .summaryPanel {
    border: 1px solid lightgrey;
    border-radius: 5px;
    position: relative;

    .content {
      padding: 10px;
      height: 90%;
    }

    .hidden {
      position: absolute;
      top: 0;
      left: 100%;
      transform-origin: top left;
      transform: rotate(90deg);
      display: flex;
      width: 175px;
      height: 43px;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid #c0c0c0;
      padding: 12px;
      top: -50;
    }
  }

  .buttons {
    grid-column-start: 2;
    align-items: center;
    display: flex;
    gap: 10px;
  }
}

.historyGoBackBtn {
  background-color: transparent;
  border: none;
  padding: 0px;
  outline: none;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  gap: 5px;
  cursor: pointer;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.custom_scroll {
  overflow-y: auto;
  padding-right: 30px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
    border-radius: 4px;
  }
}

.graph {
  grid-column-start: 1;
  grid-column-end: 4;
}

.filtersubMenuActionBtn {
  display: none;
}

.filterSubmenu {
  &:hover {
    .filtersubMenuActionBtn {
      display: block;
    }
  }
}
