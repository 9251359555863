.line-clamp {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; 
  }

.noteHeader {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 50px;
}

.__react_component_tooltip .tooltip-lineclamp {
	overflow: hidden;
	display: -webkit-box !important;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 7;
	max-height: 100px;
}

.single-line {
  -webkit-box-orient: vertical;
}

