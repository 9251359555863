.errorBoundaryContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorImage {
  height: 407px;
  width: 613px;
}

.goHomeButton {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  height: 38px;
  font-size: 16px;
  padding: 10px 24px;
  border-radius: 50px;
  background-image: linear-gradient(180deg, #fff, #f5f5fa);
  box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15),
    0 1px 3px 0 rgba(93, 100, 148, 0.2);
  transition: all 0.2s ease-out;
  border: 1px solid #ffba55;
  color: #ad6900;

  &:hover {
    box-shadow: 0 8px 22px 0 rgba(37, 44, 97, 0.15),
      0 4px 6px 0 rgba(93, 100, 148, 0.2);
  }
}
