h2 {
	text-align: center;
	margin: 0;
}

.filter-input {
	padding: 1em;
	display: flex;
	width: 40%;
}

.public_fixedDataTableCell_cellContent > a {
	cursor: pointer;
}

.actionChooserClass .public_fixedDataTableCell_cellContent {
	padding-left: 0px;
}

.informationClass .public_fixedDataTableCell_cellContent {
	padding-right: 0px;
	float: right;
}

.datagroup {
	margin-top: -10px !important;
}

.ScrollbarLayout_main {
	box-sizing: border-box;
	outline: none;
	overflow: hidden;
	position: absolute;
	transition-duration: 250ms;
	transition-timing-function: ease;
	user-select: none;
}

.ScrollbarLayout_mainVertical {
	bottom: 0;
	right: 0;
	top: 0;
	transition-property: background-color width;
	width: 15px;
}

.ScrollbarLayout_mainVertical.public_Scrollbar_mainActive,
.ScrollbarLayout_mainVertical:hover {
	width: 17px;
}

.ScrollbarLayout_mainHorizontal {
	bottom: 0;
	height: 15px;
	left: 0;
	transition-property: background-color height;
}

.ScrollbarLayout_mainHorizontal.public_Scrollbar_mainActive,
.ScrollbarLayout_mainHorizontal:hover {
	height: 17px;
}

.ScrollbarLayout_face {
	left: 0;
	overflow: hidden;
	position: absolute;
	z-index: 1;
}

.ScrollbarLayout_face:after {
	border-radius: 6px;
	content: '';
	display: block;
	position: absolute;
	transition: background-color 250ms ease;
}

.ScrollbarLayout_faceHorizontal {
	bottom: 0;
	left: 0;
	top: 0;
}

.ScrollbarLayout_faceHorizontal:after {
	bottom: 4px;
	left: 0;
	top: 4px;
	width: 100%;
}

.ScrollbarLayout_faceVertical {
	left: 0;
	right: 0;
	top: 0;
}

.ScrollbarLayout_faceVertical:after {
	height: 100%;
	left: 4px;
	right: 4px;
	top: 0;
}

.fixedDataTableCellGroupLayout_cellGroup {
	backface-visibility: hidden;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	white-space: nowrap;
}

.fixedDataTableCellGroupLayout_cellGroup > .public_fixedDataTableCell_main {
	display: inline-block;
	vertical-align: top;
	white-space: normal;
}

.fixedDataTableCellGroupLayout_cellGroupWrapper {
	position: absolute;
	top: 0;
}

.fixedDataTableCellLayout_main {
	box-sizing: border-box;
	display: block;
	overflow: hidden;
	position: absolute;
	white-space: normal;
}

.fixedDataTableCellLayout_alignRight {
	text-align: right;
}

.fixedDataTableCellLayout_alignCenter {
	text-align: center;
}

.fixedDataTableCellLayout_wrap1 {
	display: table;
}

.fixedDataTableCellLayout_wrap2 {
	display: table-row;
}

.fixedDataTableCellLayout_wrap3 {
	display: table-cell;
	vertical-align: middle;
}

.fixedDataTableCellLayout_columnResizerContainer {
	position: absolute;
	right: 0px;
	width: 6px;
	z-index: 1;
}

.fixedDataTableCellLayout_columnResizerContainer:hover {
	cursor: ew-resize;
}

.fixedDataTableCellLayout_columnResizerContainer:hover .fixedDataTableCellLayout_columnResizerKnob {
	visibility: visible;
}

.fixedDataTableCellLayout_columnResizerKnob {
	position: absolute;
	right: 0px;
	visibility: hidden;
	width: 4px;
}

.fixedDataTableColumnResizerLineLayout_mouseArea {
	cursor: ew-resize;
	position: absolute;
	right: -5px;
	width: 12px;
}

.fixedDataTableColumnResizerLineLayout_main {
	box-sizing: border-box;
	position: absolute;
	z-index: 10;
}

.fixedDataTableColumnResizerLineLayout_hiddenElem {
	display: none !important;
}

.fixedDataTableLayout_main {
	overflow: hidden;
	position: relative;
	margin: 0 auto;
}

.fixedDataTableLayout_topShadow,
.fixedDataTableLayout_bottomShadow {
	height: 4px;
	left: 0;
	position: absolute;
	right: 0;
	z-index: 1;
}

.fixedDataTableLayout_bottomShadow {
	margin-top: -4px;
}

.fixedDataTableLayout_rowsContainer {
	overflow: hidden;
	position: relative;
}

.fixedDataTableLayout_horizontalScrollbar {
	bottom: 0;
	position: absolute;
}

.fixedDataTableRowLayout_main {
	box-sizing: border-box;
	overflow: hidden;
	position: absolute;
	top: 0;
}

.fixedDataTableRowLayout_body {
	left: 0;
	position: absolute;
	top: 0;
}

.fixedDataTableRowLayout_fixedColumnsDivider {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-left-style: solid;
	border-left-width: 1px;
	left: 0;
	position: absolute;
	top: 0;
	width: 0;
}

.fixedDataTableRowLayout_columnsShadow {
	width: 4px;
}

.fixedDataTableRowLayout_rowWrapper {
	position: absolute;
	top: 0;
}

.public_Scrollbar_main.public_Scrollbar_mainActive,
.public_Scrollbar_main:hover {
	background-color: rgba(255,255,255,0.8);
	background-color: rgba(255,255,255,0.8);
}

.public_Scrollbar_mainOpaque,
.public_Scrollbar_mainOpaque.public_Scrollbar_mainActive,
.public_Scrollbar_mainOpaque:hover {
	background-color: #fff;
}

.public_Scrollbar_face:after {
	background-color: #c2c2c2;
}

.public_Scrollbar_main:hover .public_Scrollbar_face:after,
.public_Scrollbar_mainActive .public_Scrollbar_face:after,
.public_Scrollbar_faceActive:after {
	background-color: #7d7d7d;
}

.public_fixedDataTable_header .public_fixedDataTableCell_main {
	font-weight: bold;
}

.public_fixedDataTable_header,
.public_fixedDataTable_header .public_fixedDataTableCell_main {
	background-color: #f6f7f8;
	background-image: linear-gradient(#fdfdfd, #efefef);
}

.public_fixedDataTable_footer .public_fixedDataTableCell_main {
	background-color: #f6f7f8;
	border-color: #d3d3d3;
}

.public_fixedDataTable_horizontalScrollbar .public_Scrollbar_mainHorizontal {
	background-color: #fff;
}

.public_fixedDataTableCell_main {
	background-color: #fff;
	border-color: #d3d3d3;
}

.public_fixedDataTableCell_highlighted {
	background-color: #f4f4f4;
}

.public_fixedDataTableCell_cellContent {
	padding: 6px;
	font-size: 13px;
}

.public_fixedDataTableCell_columnResizerKnob {
	background-color: #0284ff;
}

.public_fixedDataTableColumnResizerLine_main {
	border-color: #0284ff;
}

.public_fixedDataTableRow_main {
	background-color: #fff;
}

.public_fixedDataTableRow_highlighted,
.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
	background-color: #f6f7f8;
}

.public_fixedDataTableRow_fixedColumnsDivider {
	border-color: #d3d3d3;
}
