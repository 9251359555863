.fixedDataTableLayout_main{
  margin: 10px 0px 0px 0px;
}

.public_fixedDataTableCell_main{
	background-image : none!important;
  background-color: none!important;
}

.public_fixedDataTable_header{
	border-bottom:2px solid black!important;
  background-color: white;
}

.public_fixedDataTableRow_highlighted, .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main{
  background-color: #efefef;
}

.public_fixedDataTable_header .public_fixedDataTableCell_main {
	font-size:12px;
	color:gray;
	font-weight:normal;
  background-color: white;
}

.fixedDataTable_selectedColumn {
  font-weight: bold;
}

.fixedDataTable_Column {
  color: #555;
}

.rowWithCursorPointer {
  cursor: pointer
}

.public_fixedDataTableCell_cellContent {
  font-size:15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-height: 17px;
  -webkit-box-orient: vertical;
}

.menuItemHidden {
  display: none !important
}

.rowWithCursorDefault {  
  cursor: 'default' 
}
